<template>
  <v-dialog
    v-model="isVisibleDeleteDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("remote_on_off_confirmation_message") }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="$emit('close', item)"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="updateItem(item)"
        >
          {{ $t("ok") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisibleDeleteDialog: Boolean,
    toggleModal: {
      type: Function,
      default() {
        return void 0;
      },
    },
    item: {
      type: Object,
      default() {
        return "";
      },
    },
  },
  methods: {
    async updateItem(editVehicle) {
      editVehicle.master_switch = editVehicle.master_switch ? 1 : 0;
      editVehicle.battery_serial_numbers = editVehicle.battery.map(
        (battery) => battery.serial_no
      );
      await this.$store
        .dispatch("vehicles/update", editVehicle)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.toggleModal();
          this.isLoadingSave = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
  },
};
</script>
